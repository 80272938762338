import React from "react"
import { graphql, Link } from "gatsby"
import "./index.scss"
import { cta_button_types, SanityBlogPost } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import BirchbrookIcon from "@assets/svg/Birchbrook_Icon_Navy.svg"
import CtaButton from "@components/CtaButton"
import ScrollUpButton from "@components/ScrollUpButton"
import { Row, Col, Image } from "react-bootstrap"
import Img from "gatsby-image"
import SEO from "@components/SEO"
import useResizeWidth from "@hooks/useResizeWidth"

type Props = {
  data: {
    sanityBlogPost: SanityBlogPost
  }
  location: Location
}

const formatDate = (dateString: string) => {
  const date = dateString ? new Date(dateString) : new Date()
  const day =
    date.getDate() + 1 >= 10 ? date.getDate() + 1 : `0${date.getDate() + 1}`
  const month =
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  const year = date.getFullYear().toString().substring(2)
  return `${month}/${day}/${year}`
}

const BlogPostPage = ({ data: { sanityBlogPost }, location }: Props) => {
  const { title, backgroundColor } = sanityBlogPost
  const { width } = useResizeWidth()
  const metadata = {
    location: location.href,
    ...sanityBlogPost?.seo,
  }
  const date = formatDate(sanityBlogPost.date)

  return (
    <>
      <SEO {...metadata} />
      <section
        className="bp-wrapper"
        style={{ backgroundColor: backgroundColor || "#DED9E5" }}
      >
        <Row noGutters className="bp-container">
          <Col className="bp-image-container">
            <Img
              fluid={sanityBlogPost?.image?.asset?.fluid}
              alt={sanityBlogPost?.image?.alt || title}
              className="bp-img"
            />
          </Col>
        </Row>
        <Row noGutters className="bp-post">
          <Col lg={4}>
            <Image
              src={BirchbrookIcon}
              className="bp-birchbrook-icon"
              alt="birchbrook"
              fluid
            />
            <div className="bp-cta-container">
              {sanityBlogPost?.file && (
                <a
                  className={`${cta_button_types.primary.toLowerCase()} cta`}
                  href={`${sanityBlogPost?.file?.asset?.url}?dl=${sanityBlogPost?.file?.asset?.originalFilename}`}
                  download={sanityBlogPost?.file?.asset?.originalFilename}
                  target="_blank"
                >
                  Download Article
                </a>
              )}
              <Link to="/viewpoints" className="secondary-button">Back to viewpoints</Link>
            </div>
          </Col>
          <Col className="bp-content-container" lg={8}>
            <div className="bp-headline">
              {" "}
              <h2> {title} </h2>
              <p className="bp-author">
                {date} | {sanityBlogPost?.author} |{" "}
                {sanityBlogPost?._rawCategory?.category || " "}{" "}
              </p>
            </div>

            <BaseBlockContent blocks={sanityBlogPost?._rawArticle} />
          </Col>
        </Row>
        {width < 768 && (
          <Row className="bp-backTop-container">
            {" "}
            <ScrollUpButton
              type="dark"
              style={{
                position: "relative",
                opacity: 1,
                right: "initial",
                bottom: "initial",
              }}
            />{" "}
          </Row>
        )}
      </section>
    </>
  )
}

export default BlogPostPage

export const query = graphql`
  query sanityBlogPost($slug: String) {
    sanityBlogPost(slug: { current: { eq: $slug } }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      slug {
        current
      }
      backgroundColor
      _rawCategory(resolveReferences: { maxDepth: 10 })
      author
      date
      image {
        asset {
          fluid(maxWidth: 10000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawArticle(resolveReferences: { maxDepth: 10 })
      file {
        asset {
          url
          originalFilename
        }
      }
    }
  }
`
